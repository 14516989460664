






























































































































import { Component } from "vue-property-decorator";
import draggable from "vuedraggable";
import AttributeMenu from "@/components/attribute/AttributeMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";
@Component({
  components: {
    draggable,
    AttributeMenu,
    Icon
  }
})
export default class AttributeTable extends Table {}
